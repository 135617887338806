import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Header from "../../components/Header";
import BgImage from "../../components/BgImage";
import { countIssues } from "../../actions/issue";
import { Doughnut } from "react-chartjs-2";

const StatisticsByReports = ({ countIssues }) => {
  const [selfReportCounts, setSelfReportCounts] = useState([0, 0]);
  const state = {
    labels: ["Prijavljujem za sebe", "Prijavljujem za drugoga"],
    datasets: [
      {
        backgroundColor: ["#00408A", "#db3e3e"],
        hoverBackgroundColor: ["#002857", "#c22424"],
        data: selfReportCounts,
      },
    ],
  };

  useEffect(() => {
    statsBySelfReports();
  }, []);

  const statsBySelfReports = async () => {
    let selfReports = await countIssues({ selfReport: true });
    let otherReports = await countIssues({ selfReport: false });
    setSelfReportCounts([selfReports, otherReports]);
  };
  return (
    <>
      <Header></Header>
      <BgImage></BgImage>
      <div className="container mx-auto">
        <div className="text-2xl text-center mt-10 uppercase">
          Statistika o prijavama za sebe/za nekog drugog
        </div>
        <Doughnut
          data={state}
          options={{
            legend: {
              display: true,
              position: "top",
            },
          }}
        />
      </div>
    </>
  );
};

const mapDispatchToProps = {
  countIssues,
};

export default connect(null, mapDispatchToProps)(StatisticsByReports);
