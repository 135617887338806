import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import Header from "../../components/Header";
import Paging from "../../components/Paging";
import IssueList from "../../components/Issues/IssueList";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getIssues } from "../../selectors/issue";
import { listIssues, deleteIssue, countIssues } from "../../actions/issue";
import Layout from "../../Layout";
import BgImage from "../../components/BgImage";

const IssueListPage = ({ listIssues, deleteIssue, issuesList }) => {
  const { isFetching, isFetched, listData, total } = issuesList;
  const {
    listData: issueListData,
    isFetching: issueListFetching,
    isFetched: issueListFetched,
  } = issuesList;

  const [pageParams, setPageParams] = useState({ _limit: 10, _start: 0 });
  useEffect(() => {
    listIssues({ _sort: "updated_at:DESC", ...pageParams });
    countIssues();
  }, [pageParams]);

  function handleChangeType(e) {
    listIssues({ reportType: e.target.value });
  }

  const handleChangeStatus = (e) => {
    listIssues({ status: e.target.value });
  };

  return (
    <Layout>
      <Header></Header>
      <BgImage></BgImage>
      <div className="container mx-auto">
        <div className="text-center text-2xl leading-tight mt-20 mb-20 uppercase">
          Prijave
        </div>
        <Formik>
          <Form>
            <div className="flex flex-1 algin-center mt-10 text-left">
              {/* <label className="text-gray-700" for="type">
                Vrsta prijave
                <Field
                  component="select"
                  id="type"
                  name="type"
                  multiple={false}
                  onChange={handleChangeType}
                  className="block w-52 py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500"
                >
                  <option>Izaberi</option>
                  <option value={"request"}>Zahtjev</option>
                  <option value={"complaint"}>Pritužba</option>
                  <option value={"violation"}>Prekršaj</option>
                </Field>
              </label> */}
              <label className="text-gray-700 mr-20" for="status">
                Status prijave
                <Field
                  component="select"
                  id="status"
                  name="status"
                  multiple={false}
                  onChange={handleChangeStatus}
                  className="block w-52 py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500"
                >
                  <option>Izaberi</option>
                  <option value={"reported"}>Prijavljeno</option>
                  <option value={"in_progress"}>Postupak u toku</option>
                  <option value={"closed"}>Zatvoreno</option>
                </Field>
              </label>
            </div>
          </Form>
        </Formik>
        <div className="mt-8 flex flex-row items-start justify-start">
          <Paging total={total} setPageParams={setPageParams}></Paging>
        </div>
        <IssueList issues={issueListData} deleteIssue={deleteIssue} />
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  issuesList: getIssues(state),
});

const mapDispatchToProps = {
  listIssues,
  deleteIssue,
  countIssues,
};

export default connect(mapStateToProps, mapDispatchToProps)(IssueListPage);
