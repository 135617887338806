import React from "react";

const PrivacyPolicy = () => {
  return (
    <>
      <div className="container mx-auto">
        <h1>Politika privatnosti</h1>
        <br />
        <p>Stupa na snagu: 01. januara 2022. godine.</p>
        <br />
        <p>
          Unija Slobodnih Sindikata Crne Gore (USSCG) upravlja mobilnom
          aplikacijom USSCG - Zaštiti svoja prava (“Usluga“).
        </p>
        <br />
        <p>
          Na ovoj strani naći ćete informacije o prikupljanju, upotrebi i
          otkrivanju ličnih podataka kada koristite naše usluge, kao i
          informacije o tome koje izbore treba povezati sa tim podacima.
        </p>
        <br />
        <p>
          Vaše podatke koristimo da bismo obezbijedili i poboljšali Uslugu.
          Korišćenjem Usluge, Vi pristajete na to da informacije budu
          prikupljene i upotrijebljene u skladu sa ovom politikom. Osim ako je
          drugačije utvrđeno datom Politikom privatnosti, pojmovi koji se
          koriste u ovoj Politici privatnosti imaju isto značenje kao i u našim
          Uslovima o korišćenju.
        </p>
        <br />
        <h1>Zašto aplikacija koristi lične podatke?</h1>
        <h1>Korisnički nalog</h1>
        <br />
        <p>
          Da biste mogli da sinhronizujete svoje podatke na nekoliko uređaja i
          da koristite usluge aplikacije “USSCG - Zaštiti svoja prava“,
          neophodno je da kreirate nalog.
        </p>
        <br />
        <p>
          Podaci o nalogu su klasifikovani kao lični samo ukoliko se mogu
          koristiti da bi Vas identifikovali.
        </p>
        <br />
        <p>Postoje dva načina za kreiranje korisničkog naloga: </p>
        <ul>
          <li>- unošenjem Vaše e-mail adrese i kreiranjem lozinke,</li>
          <li>
            - ili korišćenjem detalja za prijavljivanje na Google, Facebook ili
            Apple ID.
          </li>
        </ul>
        <p>
          Аko odaberete da kreirate nalog na aplikaciji “USSCG - Zaštiti svoja
          prava“ preko e-maila, aplikacija će koristiti dati e-mail u cilju
          identifikacije Vašeg korisnički nalog.
        </p>
        <br />
        <p>
          Ako odaberete da kreirate nalog za aplikaciju “USSCG - Zaštiti svoja
          prava“ korišćenjem detalja za prijavljivanje na Google-u, Facebook-u
          ili Apple ID-ju, аplikacija će uputiti zahtjev za povezivanje sa vašim
          nalogom uz zahtjev da poveže aplikaciju “USSCG - Zaštiti svoja prava“
          i preuzeće e-mail, ime i fotografiju povezanog naloga. U tom slučaju,
          Google, Facebook ili Apple mogu prikupiti podatke u vezi sa
          povezanošću sa Vašim korisničkim nalogom na aplikaciju “USSCG -
          Zaštiti svoja prava“. Veza sa korisničkim nalogom aplikacije “USSCG -
          Zaštiti svoja prava“ ostaće aktivna 30 dana. Preporučujemo da prvo
          pročitate politiku o privatnosti Google-a, Facebook-a ili Apple-a.
        </p>
        <br />
        <p>
          Aplikacija koristi ove podatke jedino da bi Vas mogla identifikovati
          kada se prijavite u aplikaciju. Vaši podaci neće biti predati trećim
          stranama ni pod kakvim okolnostima.
        </p>
        <br />
        <h1>Prikupljanje i upotreba informacija</h1>
        <br />
        <p>
          Mi prikupljamo nekoliko različitih vrsta informacija za različite
          svrhe da bismo obezbijedili i poboljšali našu Uslugu.Vrste podataka
          koji se prikupljaju:
        </p>
        <br />
        <p>Lični podaci</p>
        <br />
        <p>
          Kada koristite našu Uslugu, možemo zatražiti od Vas neke lično
          prepoznatljive informacije koje se mogu koristiti za kontakt sa vama
          ili za vašu identifikaciju („lični podaci “). Lične prepoznatljive
          informacije mogu obuhvatiti, ali se ne ograničavaju samo na:
        </p>
        <br />
        <ul>
          <li>- e-mail,</li>
          <li>- ime i prezime,</li>
          <li>- pol i uzrast,</li>
          <li>- broj telefona.</li>
        </ul>
        <p>Podaci o korišćenju</p>
        <br />
        <p>
          Kada pristupate Usluzi putem Vašeg mobilnog uređaja, mi moramo
          automatski pristupiti i prikupiti određene informacije, uključujući i,
          ali ne ograničavajući se na, tip mobilnog uređaja koji koristite,
          jedinstveni ID broj Vašeg mobilnog uređaja, IP adresu Vašeg mobilnog
          uređaja, operativni sistem Vašeg mobilnog uređaja, galeriju sa
          fotografijama, tip pretraživača koji koristite, jedinstvene
          identifikatore uređaja i druge dijagnostičke podatke („podaci o
          korišćenju“).
        </p>
        <br />
        <h1>Korišćenje podataka</h1>
        <br />
        <p>
          Unija slobodnih sindikata Crne Gore koristi prikupljene podatke u
          razne svrhe:
        </p>
        <br />
        <ul>
          <li>- za obezbjeđivanje i održavanje Usluge,</li>
          <li>
            - za prijavljivanje kršenja radničkih prava nadležnim institucijama,
          </li>
          <li>- da bi Vas obavijestili o promjeni u našim Uslugama</li>
          <li>- da bi Vas obavijestili o promjeni u našim Uslugama,</li>
          <li>
            - da bi Vam omogućili da učestvujete u interaktivnim opcijama Usluge
            kada i to poželite,
          </li>
          <li>- da bi obezbijedili brigu i podršku o klijentima,</li>
          <li>
            - da bi obezbijedili analizu i vrijedne informacije da bismo mogli
            da poboljšamo Uslugu,{" "}
          </li>
          <li>- za praćenje korišćenja Usluge,</li>
          <li>
            - za otkrivanje, spriječavanje i rešavanje tehničkih pitanja.{" "}
          </li>
        </ul>
        <br />
        <p>Prenos podataka</p>
        <br />
        <p>
          Vaše informacije, uključujući i lične podatke, mogu biti prenesene – i
          čuvane – na kompjuterima koji se nalaze van vaše države, regiona ili
          sudske nadležnosti, gdje se zakoni o zaštiti podataka mogu razlikovati
          od onih u vašoj sudskoj nadležnosti. Ukoliko se nalazite van Crne Gore
          i odlučite da nam date informacije, treba da znate da prenosimo
          podatke, uključujući i lične podatke, u Crnu Goru i tamo ih
          obrađujemo.
        </p>
        <br />
        <p>
          Vaša saglasnost za Politiku privatnosti zajedno sa dostavljenim
          informacijama znači da pristajete na takav prenos.
        </p>
        <br />
        <p>
          Unija slobodnih sindikata Crne Gore će preuzeti sve potrebne razumne
          korake da bi bili sigurni da su Vaši podaci bezbjedni u da su u skladu
          sa Politikom privatnosti i Vaši lični podaci se neće prenositi u
          organizaciju ili državu koja nema odgovarajuću kontrolu, uključujući i
          bezbjednost vaših podataka i drugih ličnih informacija.
        </p>
        <br />
        <h1>Otkrivanje podataka</h1>
        <br />
        <h1>Zakonski uslovi</h1>
        <br />
        <p>
          Unija slobodnih sindikata Crne Gore može učiniti dostupnim Vaše lične
          podatke u slučaju kada vjeruje da je to neophodno za:
        </p>
        <br />
        <ul>
          <li>- postupanje u skladu sa zakonskim obavezama, </li>
          <li>
            - zaštitu i odbranu prava ili vlasništva Unija slobodnih sindikata
            Crne Gore,
          </li>
          <li>
            - za sprječavanje ili ispitivanje mogućeg nezakonitog djela
            povezanog sa Uslugom,
          </li>
          <li>
            - za zaštitu lične bezbjednosti korisnika Usluge ili javnosti,
          </li>
          <li>- za zaštitu od zakonske odgovornosti.</li>
        </ul>
        <h1>Bezbjednost podataka</h1>
        <br />
        <p>
          Bezbjednost Vaših podataka nam je veoma važna, ali morate imati na umu
          da niti jedan način prenosa putem interneta ili način elektronskog
          čuvanja nije 100% bezbjedan. Uprkos naporima da koristimo komercijalno
          prihvatljiva sredstva za zaštitu Vaših ličnih podataka, nismo u
          mogućnosti da garantujemo apsolutnu bezbjednost.
        </p>
        <br />
        <h1>Pružatelj usluga</h1>
        <br />
        <p>
          Možemo angažovati treću stranu, društvo ili pojedince da bismo Vam
          olakšali vašu Uslugu („Pružatelj usluga“), koji obezbjeđuju Uslugu u
          naše ime, vrše usluge povezane sa našom Uslugom ili nam pomažu oko
          analiziranja načina na koji se koristi naša Usluga. Ove treće strane
          imaju pristup Vašim ličnim podacima samo da bi obavili zadatak u Vaše
          ime i u obavezi su da ne čine dostupnim ili da ne upotrebljavaju
          podatke u druge svrhe.
        </p>
        <br />
        <h1>Analitika</h1>
        <br />
        <p>
          Aplikacija ne koristi statističke podatke za obradu ličnih podataka
        </p>
        <br />
        <h1>Linkovi ka drugim stranama</h1>
        <br />
        <p>
          Naša Usluga može da sadrži linkove ka drugim stranama kojima ne
          upravljamo mi. Kada kliknete na link treće strane, bićete preusmjereni
          ka toj trećoj strani. Savetujemo Vam da pogledate Politiku privatnosti
          svake od strana koju posjećujete. Mi nemamo kontrolu i ne preuzimamo
          odgovornost za sadržinu, politike ili prakse privatnosti strana ili
          usluga trećih lica.
        </p>
        <br />
        <h1>Privatnost djece</h1>
        <br />
        <p>
          Naša usluga se ne odnosi na osobe mlađe od 18 godina („djeca“). Mi ne
          prikupljamo svjesno podatke o ličnim prepoznatljivim informacijama
          osoba mlađih od 18 godina. Ukoliko ste roditelj ili staratelj i znate
          da nam je Vaše dijete dalo lične podatke, kontaktirajte nas. Ukoliko
          dođemo do saznanja da smo prikupili lične podatke djece bez potvrde
          roditeljske saglasnosti, preuzećemo korake da odstranimo te podatke sa
          naših servera.
        </p>
        <br />
        <h1>Promjena ove Politike privatnosti</h1>
        <br />
        <p>
          S vremena na vrijeme, možemo ažurirati našu Politiku privatnosti.
          Obavijestićemo Vas o ovim izmjenama objavljivanjem nove Politike
          privatnosti na ovoj strani. Obavijestićemo Vas putem е-maila i/ili
          saopštenja o našoj Usluzi prije nego što te promjene stupe na snagu i
          ažuriraćemo „datum stupanja na snagu“ na početku ove Politike
          privatnosti.
        </p>
        <p>
          Savjetujemo vam da povremeno provjeravate Politiku privatnosti o
          mogućim izmjenama. Promjene u Politici privatnosti su važeće od
          trenutka objavljivanja na ovoj strani.
        </p>
        <br />
        <h1>Kontaktirajte nas</h1>
        <br />
        <p>
          Ukoliko imate pitanja u vezi sa ovom Politikom privatnosti, možete nas
          kontaktirati putem е-maila: usscg@usscg.me
        </p>
        <p>
          Aplikacija „USSCG - Zaštiti svoja prava“ je podržana od strane
          Međunarodne organizacije rada.
        </p>
        <br />
      </div>
    </>
  );
};
export default PrivacyPolicy;
