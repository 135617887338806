import React, { useState, useEffect } from "react";
import * as yup from "yup";
import { useParams, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Header from "../../components/Header";
import BgImage from "../../components/BgImage";
import avatar from "../../img/nophoto.jpg";
import { createPost, updatePost, fetchPost } from "../../actions/post";
import { getPostDetails } from "../../selectors/post";
import Layout from "../../Layout";

const PostSchema = yup.object().shape({
  title: yup.string().required("Ovo polje je obavezno"),
  subtitle: yup.string(),
  content: yup.string().required("Ovo polje je obavezno"),
  link: yup.string().url(),
});

const startValues = {
  title: "",
  subtitle: "",
  content: "",
  featuredImage: "",
  link: "",
};

const CreatePost = ({
  createPost,
  updatePost,
  fetchPost,
  postDetails,
  postList,
}) => {
  const [initialValues, setInitialValues] = useState(startValues);

  const {
    postData,
    isFetching: postDataIsFetching,
    isFetched: postDataIsFetched,
  } = postDetails;

  let { id } = useParams();
  const history = useHistory();
  const [file, setFile] = useState(null);

  const handleAttachment = async ({ target }) => {
    if (!target.files.length) {
      return false;
    }
    setFile(target.files[0]);
  };

  useEffect(() => {
    if (id) {
      fetchPost(id);
    }
  }, []);

  const formSubmit = async (values) => {
    try {
      let post = null;
      if (id) {
        post = await updatePost(id, file, values);
      } else {
        post = await createPost(file, values);
      }
      history.push("/posts");
    } catch (e) {
      console.log(e);
      alert("Something went wrong, please try again");
    }
  };

  useEffect(() => {
    if (id && postData) {
      setInitialValues({
        title: postData.title || "",
        subtitle: postData.subtitle || "",
        content: postData.content || "",
        featuredImage: postData.featuredImage || "",
        link: postData.link || "",
      });
    }
  }, [postData]);

  return (
    <Layout>
      <Header></Header>
      <BgImage></BgImage>
      <div className="container w-2/4 m-0 mx-auto">
        <div className="text-2xl text-center mt-10 uppercase">
          DODAJ NOVU VIJEST
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={PostSchema}
          validateOnChange={false}
          enableReinitialize={true}
          onSubmit={formSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className="">
                <div className="mt-10">
                  <div className="relative">
                    <label htmlFor="title" className="text-gray-700">
                      Naslov
                      <span className="text-red required-dot">*</span>
                    </label>
                    <Field
                      name="title"
                      placeholder="Unesi naslov"
                      type="text"
                      className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                    ></Field>
                    <div className="text-red text-center mb-10">
                      <ErrorMessage name="title" />
                    </div>
                  </div>
                </div>
                <div className="mt-10">
                  <div className="relative">
                    <label htmlFor="subtitle" className="text-gray-700">
                      Podnaslov
                    </label>
                    <Field
                      name="subtitle"
                      placeholder="Unesi podnaslov"
                      type="text"
                      className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                    ></Field>
                  </div>
                </div>
                <div className="mt-10">
                  <label htmlFor="content" className="text-gray-700 mt-10">
                    Тekst
                    <span className="text-red required-dot">*</span>
                  </label>
                  <Field
                    name="content"
                    component="textarea"
                    rows="10"
                    className="flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 rounded-lg text-base focus:outline-none"
                  ></Field>
                  <div className="text-red text-center mb-10">
                    <ErrorMessage name="content" />
                  </div>
                </div>
                <div className="mt-10">
                  <label
                    htmlFor="featuredImage"
                    className="text-gray-700 mt-10"
                  >
                    Fotografija
                  </label>
                  <span className="text-red required-dot">*</span>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleAttachment}
                    className="text-center text-base px-20 py-3 mt-5 w-4/5 md:w-6/12"
                  />
                </div>
                <div className="mt-10">
                  <label htmlFor="link" className="text-gray-700">
                    Link
                  </label>
                  <Field
                    name="link"
                    placeholder="Unesi link"
                    type="text"
                    className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                  ></Field>
                </div>
                <div className="w-1/4 mt-16 mb-20 block mx-auto m-0 text-center">
                  <button
                    disabled={isSubmitting}
                    type="submit"
                    className="py-2 px-4 flex justify-center items-center bg-green-600 hover:bg-green-700 focus:green-green-500 focus:ring-offset-green-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg disabled:opacity-50"
                  >
                    Sačuvaj
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  postDetails: getPostDetails(state),
});

const mapDispatchToProps = {
  createPost,
  fetchPost,
  updatePost,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreatePost);
