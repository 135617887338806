import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";

const InternalReportList = ({ internalReports, ...props }) => {
  return (
    <>
      <div className="container mx-auto">
        <div className="py-8">
          <div className="shadow rounded-lg">
            <table className="min-w-full leading-normal">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="px-5 py-3 bg-white border-b border-t border-gray-200 text-black text-left text-sm uppercase font-bold"
                  >
                    Redni br.
                  </th>
                  <th
                    scope="col"
                    className="px-5 py-3 bg-white border-b border-t border-gray-200 text-black text-left text-sm uppercase font-bold"
                  >
                    Prijava
                  </th>
                  <th
                    scope="col"
                    className="px-5 py-3 bg-white border-b border-t border-gray-200 text-black text-left text-sm uppercase font-bold"
                  >
                    Kompanija
                  </th>
                  <th
                    scope="col"
                    className="px-5 py-3 bg-white border-b border-t border-gray-200 text-black text-left text-sm uppercase font-bold"
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    className="px-5 py-3 bg-white border-b border-t border-gray-200 text-black text-left text-sm uppercase font-bold"
                  >
                    Доделено на
                  </th>
                  <th
                    scope="col"
                    className="px-5 py-3 bg-white border-b border-t border-gray-200 text-black text-left text-sm uppercase font-bold"
                  >
                    Основ
                  </th>
                  <th
                    scope="col"
                    className="px-5 py-3 bg-white border-b border-t border-gray-200 text-black text-left text-sm uppercase font-bold"
                  >
                    Ажурирано на
                  </th>
                  <th
                    scope="col"
                    className="px-5 py-3 bg-white border-b border-t border-gray-200 text-black text-left text-sm uppercase font-bold"
                  >
                    Акција
                  </th>
                  <th
                    scope="col"
                    className="px-5 py-3 bg-white border-b border-t border-gray-200 text-black text-left text-sm uppercase font-bold"
                  ></th>
                </tr>
              </thead>
              <tbody>
                {internalReports.map((item, idx) => {
                  console.log("interni prijavi");
                  console.log(internalReports);
                  let reportType = "";
                  let type = item.report.reportType;
                  if (type === "request") {
                    reportType = "Барање";
                  } else if (type === "complaint") {
                    reportType = "Жалба";
                  } else {
                    reportType = "Прекршок";
                  }

                  let reportStatus = "";
                  let status = item.status;
                  if (status === "REPORTED") {
                    reportStatus = "Отворена";
                  } else if (status === "ASSIGNED_REGIONAL") {
                    reportStatus = "Доделено на регионален инспектор";
                  } else if (status === "ASSIGNED_SUBINSPECTOR") {
                    reportStatus = "Доделено на подинспектор";
                  } else if (status === "RETURNED_REGIONAL") {
                    reportStatus = "Вратено на регионален инспектор";
                  } else if (status === "RETURNED_SSM") {
                    reportStatus = "Вратено на ССМ";
                  }

                  let reportBasis = "";
                  let basis = item.report_basis;
                  if (basis === "SECURITY_AND_PROTECTION") {
                    reportBasis = "Безбедност и заштита при работа";
                  } else if (basis === "RIGHTS_VIOLATION") {
                    reportBasis = "Прекршување на работнички права";
                  } else {
                    reportBasis = "/";
                  }

                  return (
                    <tr {...props}>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {idx + 1}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {reportType}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {item.report.company}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {reportStatus}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {item.assigned_regional_inspector != null
                            ? item.assigned_regional_inspector.firstName +
                              " " +
                              item.assigned_regional_inspector.familyName
                            : ""}
                          <br />
                          {item.assigned_subinspector != null
                            ? item.assigned_subinspector.firstName +
                              " " +
                              item.assigned_subinspector.familyName
                            : ""}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {reportBasis}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {moment(item.report.updated_at).format("DD.MM.YYYY")}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <Link
                          to={`/internalReportDetails/${item.id}`}
                          className="text-blue"
                        >
                          Види детали
                        </Link>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm"></td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default InternalReportList;
